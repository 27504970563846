<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div class="ml-3 mr-3" v-else>
        <div class="fl-te-c mb-3">
            <h4>Purchase Order Details</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/finance/order/list/')"
                     text="Back"></btn>
                <btn size="sm" icon="fa fa-history" @click="$router.push('/finance/order/' + id + '/history/')"
                     text="History"></btn>
            </div>
        </div>
        <div v-if="data" class="card bs-4 p-0 pb-3 mb-4">
            <div class="fl-te-c mb-3 bb-1 p-3">
                <h5>Order Details</h5>
                <div class="btn-group" v-if="data.status==='PA Approved'">
                    <btn size="sm" :loading="loading" loading-text="Rejecting..." @click="buttonClicked(false)"
                         text="Reject"></btn>
                    <btn size="sm" :loading="loading1" loading-text="Approving..." @click="buttonClicked(true)"
                         text="Approve"></btn>
                </div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Purchase Order ID</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.order_id }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Created by</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_by }}</div>
                <div class="col-2"></div>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-2 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.status }}</div>
                <div class="col-2"></div>
                <div class="col-2 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted">{{ data.created_date }}</div>
                <div class="col-2"></div>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data.items.length!==0" :data="data.items"
                                 :fields="fields" ref="table">
                <template #remarks="{rowData}">
                    <validated-input v-if="data.status==='PA Approved'" placeholder="Enter Remarks" name="Remarks"
                                     v-model="rowData.remarks"
                                     :rules="{required: false}"/>
                    <span v-else>{{ rowData.finance_remarks }}</span>
                </template>
            </custom-simple-table>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '../../../../data/urls';

export default {
    name : 'DetailsPage',
    data () {
        return {
            DataLoading : false,
            loading     : false,
            loading1    : false,
            id          : this.$route.params.id,
            data        : '',
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'No'
                },
                {
                    name      : 'product',
                    sortField : 'product',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'unit_price',
                    sortField : 'unit_price',
                    title     : 'Price'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                },
                {
                    name  : '__slot:remarks',
                    title : 'F A Remarks'
                }

            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.financeAdmin.purchaseOrder.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.DataLoading = false;
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            that.DataLoading = false;
        },
        async buttonClicked (confirm) {
            const dict = {};
            dict.remarks = this.data.items[0].remarks;
            dict.id = this.data.items[0].id;
            dict.approve = confirm;
            if (confirm) {
                this.loading1 = true;
            } else {
                this.loading = true;
            }
            const that = this;
            const response = await axios.form(urls.financeAdmin.purchaseOrder.approveOrder, dict);
            const json = response.data;
            if (json.error === false) {
                that.$notify(json.msg, 'Success', {
                    type : 'success'
                });
                that.loading = false;
                that.loading1 = false;
                that.$router.push('/finance/order/list/');
            } else {
                that.$notify('Unable to approve the order.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
                that.loading1 = false;
            }
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
